.list {
  text-align: left;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

.list-group-item{
  padding: 0.2rem 0.5rem;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.layout-main-container {
  margin: 0 1rem;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  font-weight: 500;
}

.card {
  margin-top: 10px;
}

.card-kpi-green{
  border-radius: 1rem;
    background: #14b8a6
}
.card-kpi-red{
  border-radius: 1rem;
    background: #b81414
}

.chip {
  flex: 1 0 calc(50% - 10px); /* Set each chip to take up 50% of the container width with gap */
  padding: 5px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  margin: 5px; /* Add margin for spacing between chips */
  text-align: center;
}

.font-bold{
  font-weight: bold;
}

.border-t {
  border-top-width: 1px;
}

.py-3rem {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.property-details-chip {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: Sora, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  flex: 1 0 calc(50% - 10px); /* Set each chip to take up 50% of the container width with gap */
  padding: 5px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
}

@media screen and (min-width: 600px) {
  .chip {
   /* flex-basis: calc(25% - 10px);  Set max width to ensure at most 4 chips per row with gap */
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap; /* Allow chips to wrap to the next line */
}

.icon {
  display: block; /* By default, icons are displayed */
}


.price-bold-and-big {
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}


.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.p-3rem {
  padding: .25rem;
}

.p-4rem {
  padding: 4rem;
}

.rounded-md {
  border-radius: .375rem;
}

.items-center {
  align-items: center;
}

/* Media query for mobile devices */
@media screen and (max-width: 600px) {
  .icon {
    display: none !important; /* Hide icons on smaller screens */
  }
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 204 21 / var(--tw-bg-opacity));
}

.h-2 {
  height: .5rem;
}

.w-1 {
  width: 3.3333% !important;
}

.rounded-full {
  border-radius: 9999px;
}

.form-row {
  display: flex;
  align-items: center;
  margin-bottom: .25rem; /* Adjust as needed */
}

.form-row label {
  width: 9rem;
    margin-right: 1rem; /* Adjust as needed */
}

.form-row input {
  flex: 1;
}

.h-\[19\.5rem\] {
  height: 19.5rem;
}
.w-\[26\.5rem\] {
  width: 26.5rem;
}


.thumbnail-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Small devices (mobile, 600px and below) */
@media (max-width: 600px) {
  .thumbnail-img {
    /* max-width: 23.5rem; */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 600px) and (max-width: 768px) {
  .thumbnail-img {
    max-width: 25rem;
  }
}

/* Large devices (desktops, 1024px and up) */
@media (min-width: 768px) and (max-width: 1024px) {
  .thumbnail-img {
    max-width: 30rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1024px) {
  .thumbnail-img {
    max-width: 30rem;
  }
}

.property-node {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  
}

.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
  outline: 0 none;
  border-bottom: 1px solid #e0e0e0;
}

.list-thumbnail-img {
  width: 80px;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}
.thumbnail-container {
  position: relative;
}

.list-thumbnail-img {
  width: 80px;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

.status-dot {
  position: absolute;
  top: 1px;
  /* right: 5px; */
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.green-dot {
  background-color: green;
}

.red-dot {
  background-color: red;
}

.list-thumbnail-img {
  width: 80px;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler 
{
  display: none;
}
/* Responsive design */
@media (max-width: 600px) {
  
}

@media (min-width: 600px) and (max-width: 768px) {
  .list-thumbnail-img {
    width: 100px;
  }
}

@media (min-width: 768px) {
  .list-thumbnail-img {
    width: 150px;
  }
}

.financial {
  font-size: 12px;
  color: #007bff;
}

.financial .positive {
  color: #007bff;
}

.financial .negative {
  color: red;
}

.text-14{
  font-size: 14px;
}


.product-list-item {
  display: flex;
  align-items: center;
  padding: 1rem 5rem;
}

.product-image {
  width: 100px;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

.product-list-detail {
  flex: 1;
  margin-left: 1rem;
}

.product-address {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.product-bedbath,
.product-size,
.product-financial {
  margin-bottom: 0.5rem;
}

.sticky-sidebar-header {
  position: sticky;
  top: 0;
  z-index: 1; 
}

/* Responsive design */
@media (max-width: 600px) {
  

  .product-image {
    width: 100%;
    height: auto;
  }

  .product-list-detail {
    margin-left: 0;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .product-image {
    width: 100px;
  }
}

@media (min-width: 768px) {
  .product-image {
    width: 150px;
  }
}

.sticky-image {
  position: sticky;
  top: 0;
  z-index: 1; /* Ensure the sticky image stays above other content */
}

.sticky-sidebar-header {
  position: sticky;
  top: 0;
  z-index: 1; /* Ensure the sticky image stays above other content */
}

.sticky-image img {
  width: 100%; /* Ensure the image takes the full width of the container */   
  max-height: 400px;
}

.custom-header {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  background-color: white; /* Ensure the background color matches your theme */
}

.fixed {
  position: fixed;
}

.status-label {
  position: absolute;
  top: 10px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

.close-button {
  position: absolute;
  top: 7px; /* Adjust as needed */
  right: -7px; /* Adjust as needed */
  background-color: rgb(255, 255, 255); /* Semi-transparent background to make it visible over the image */
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: black; /* Change color as needed */
  z-index: 2; /* Ensure it stays above the image */
  background: 'white';
  boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
}

.p-sidebar-header{
  display: none;
}
.p-tree {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: .5rem;
  border-radius: 3px;
}

.p-sidebar-content {
  /* overflow-y: auto; */
  flex-grow: 1;
}

.btn-chgpwd{
  top: 8px;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  z-index: 1;
  display: block;
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #ffffff; 
  /* transition: 500ms cubic-bezier(0.35, 0, 0.25, 1); */
}



.landing-page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-section {
  padding: 2rem;
}

.tagline {
  color: #6366F1;
  font-weight: bold;
  text-transform: uppercase;
}

.headline {
  --framer-font-family: "EB Garamond", "EB Garamond Placeholder", serif;
  --framer-font-family-bold: "EB Garamond", "EB Garamond Placeholder", serif;
  --framer-font-family-bold-italic: "EB Garamond", "EB Garamond Placeholder", serif;
  --framer-font-family-italic: "EB Garamond", "EB Garamond Placeholder", serif;
  --framer-font-size: 56px;
  --framer-font-style: normal;
  --framer-font-style-bold: normal;
  --framer-font-style-bold-italic: italic;
  --framer-font-style-italic: italic;
  --framer-font-weight: 400;
  --framer-font-weight-bold: 700;
  --framer-font-weight-bold-italic: 700;
  --framer-font-weight-italic: 400;
  --framer-letter-spacing: -1px;
  --framer-line-height: 1.1em;
  --framer-paragraph-spacing: 0px;
  --framer-text-alignment: start;
  --framer-text-color: var(--token-2954d5b1-45a6-4c56-ba74-b03a72c65400, #18181b);
  --framer-text-decoration: none;
  --framer-text-transform: none;
  font-family: var(--framer-font-family, Inter, Inter Placeholder, sans-serif);
  font-style: var(--framer-font-style, normal);
  font-weight: var(--framer-font-weight, 400);
  color: var(--framer-text-color, #000);
  font-size: calc(var(--framer-font-size, 16px) * var(--framer-font-size-scale, 1));
  letter-spacing: var(--framer-letter-spacing, 0);
  text-transform: var(--framer-text-transform, none);
  text-decoration: var(--framer-text-decoration, none);
  line-height: var(--framer-line-height, 1.2em);
  text-align: var(--framer-text-alignment, start);
  -webkit-text-stroke-width: var(--framer-text-stroke-width, initial);
  -webkit-text-stroke-color: var(--framer-text-stroke-color, initial);
  --framer-text-alignment: center;
}

.description {
  font-size: 1.2rem;
  margin: 2rem;
  text-align: center;
}

.register-button {
  background-color: #6366F1;
  color: #fff;
  border: none;
  margin-bottom: 2rem;
}

.newsletter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsletter .p-float-label {
  margin-right: 1rem;
}

.newsletter-button {
  background-color: #6366F1;
  color: #fff;
  border: none;
  margin: 1px;
}

.right-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* styles.css */
.iframe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

#iframewin {
  height: 100%;
  border: none;
}

#zcampaignOptinForm {
  padding: 0px !important;
}

.p-menuitem {
  text-align: center; /* Center-align text */
  justify-content: center; /* Center-align content horizontally */
  display: flex; /* Make menu items flex containers */
}

.p-menuitem-link {
  justify-content: center; /* Center-align links horizontally */
  display: flex; /* Make links flex containers */
}

.sticky-header {
  position: sticky;
  top: 0; /* Makes the header stick to the top of the viewport */
  background-color: white; /* Prevents transparency over scrolling content */
  z-index: 10; /* Keeps it above other elements */
  padding: 10px 20px;
  border-bottom: 1px solid #ddd; /* Optional: A bottom border for separation */
}

.address-nav-container {
  display: flex;
  justify-content: space-between; /* Aligns address on the left, navigation on the right */
  align-items: center; /* Vertically centers the content */
}

.address {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.navigation {
  display: flex;
  gap: 10px; /* Adds spacing between "Previous" and "Next" */
}

.nav-link {
  text-decoration: none;
  font-size: 14px;
  color: #007bff; /* Matches PrimeReact styling */
  font-weight: bold;
}

.nav-link:hover {
  text-decoration: underline; /* Adds a hover effect */
}
