.p-tabmenu {
    background: var(--surface-card);
    border: none;
  }
  
  .p-tabmenu .p-tabmenu-nav {
    border: none;
    padding: 0 1rem;
  }
  
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin: 0;
  }
  
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    padding: 1rem;
    border: none;
    border-bottom: 2px solid transparent;
    transition: all 0.2s;
    border-radius: 0;
  }
  
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: transparent;
    border-color: var(--primary-color);
    color: var(--primary-color);
  }
  
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background: transparent;
    border-color: var(--surface-300);
    color: var(--primary-color);
  }
  
  @media screen and (max-width: 768px) {
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
      padding: 0.75rem 0.5rem;
    }
    
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-text {
      font-size: 0.875rem;
    }
  }